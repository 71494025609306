// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-js": () => import("./../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-cennik-js": () => import("./../src/pages/cennik.js" /* webpackChunkName: "component---src-pages-cennik-js" */),
  "component---src-pages-diagnoza-i-terapia-js": () => import("./../src/pages/diagnoza-i-terapia.js" /* webpackChunkName: "component---src-pages-diagnoza-i-terapia-js" */),
  "component---src-pages-diagnoza-i-terapiass-js": () => import("./../src/pages/diagnoza-i-terapiass.js" /* webpackChunkName: "component---src-pages-diagnoza-i-terapiass-js" */),
  "component---src-pages-index-js": () => import("./../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-kontakt-js": () => import("./../src/pages/kontakt.js" /* webpackChunkName: "component---src-pages-kontakt-js" */),
  "component---src-pages-kursy-i-szkolenia-js": () => import("./../src/pages/kursy-i-szkolenia.js" /* webpackChunkName: "component---src-pages-kursy-i-szkolenia-js" */),
  "component---src-pages-o-mnie-js": () => import("./../src/pages/o-mnie.js" /* webpackChunkName: "component---src-pages-o-mnie-js" */),
  "component---src-pages-wiadomosc-wyslana-js": () => import("./../src/pages/wiadomosc-wyslana.js" /* webpackChunkName: "component---src-pages-wiadomosc-wyslana-js" */),
  "component---src-templates-author-template-author-template-js": () => import("./../src/templates/author-template/author-template.js" /* webpackChunkName: "component---src-templates-author-template-author-template-js" */),
  "component---src-templates-blog-list-blog-list-js": () => import("./../src/templates/blog-list/blog-list.js" /* webpackChunkName: "component---src-templates-blog-list-blog-list-js" */),
  "component---src-templates-category-template-category-template-js": () => import("./../src/templates/category-template/category-template.js" /* webpackChunkName: "component---src-templates-category-template-category-template-js" */),
  "component---src-templates-date-template-date-template-js": () => import("./../src/templates/date-template/date-template.js" /* webpackChunkName: "component---src-templates-date-template-date-template-js" */),
  "component---src-templates-search-template-search-template-js": () => import("./../src/templates/search-template/search-template.js" /* webpackChunkName: "component---src-templates-search-template-search-template-js" */),
  "component---src-templates-single-blog-single-blog-js": () => import("./../src/templates/single-blog/single-blog.js" /* webpackChunkName: "component---src-templates-single-blog-single-blog-js" */),
  "component---src-templates-tag-template-tag-template-js": () => import("./../src/templates/tag-template/tag-template.js" /* webpackChunkName: "component---src-templates-tag-template-tag-template-js" */)
}

